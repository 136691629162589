//ドメイン名
const domainName = (new URL(window.location.href)).hostname

//ダミーイメージを設定
export const altImageDumy = (event) => {
  event.target.onerror=null
  event.target.src=`/${domainName}/images/lotteries/lot_dummy.png`
}

//画像ファイルをインポートする
export const getImage = (fileName) => {
  return `/${domainName}/images/${fileName}`
}
