import { defineStore } from 'pinia'
import Cookies from "js-cookie"

//ユーザー情報用ストア
export const useStoreUserInfo = defineStore('userInfo', {
  state: () => ({
    userId: '',
    userToken: '',
    ageChecked: false
  }),
  persist: [
    {
      key: 'kuji',
      storage: {
        getItem: key => Cookies.get(key),
        setItem: (key, value)=> Cookies.set(key, value,{expires:365,secure: true}),
        removeItem: key => Cookies.remove(key),
      },
      paths: ['userId', 'userToken']
    },
    {
      storage: sessionStorage,
      paths: ['ageChecked']
    },
  ],
  getters: {
    authenticated: (state) => {
      if(state.userToken) {
        const tokenArray = state.userToken.split('.')
        const tokenPayload = JSON.parse(atob(tokenArray[1]))
        //現在時刻はトークンの有効期限より小さい場合、トークン有効とする
        return new Date().getTime() < new Date(tokenPayload?.expiresAt).getTime()
      }
      return false
    }
  },
  actions: {
    setUserInfo(_userId, _userToken) {
      this.userId = _userId
      this.userToken = _userToken
    },
    clearUserInfo() {
      this.userId = ''
      this.userToken = ''
      this.ageChecked = false
    },
    setAgeChecked(_ageChecked) {
      this.ageChecked = _ageChecked
    }
  }
})