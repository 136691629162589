import { request } from './apiClient'
import { API_URL } from '@/config/env'

// システム情報取得
export const getSystemSettings = async () => {
  const url =`${API_URL}/system`
  return await request.getFullUrl(url,{})
}

// テナント情報取得
export const getTenantSettings = async (id) => {
  const url =`tenant/${id}`
  return await request.get(url)
}