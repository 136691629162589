import axios from 'axios'
import { useStoreConfig } from '@/stores/config'
import { useStoreMessages } from '@/stores/messages'
import { getHtmlFileName } from '@/utils/htmlUtil'
import { useStoreUserInfo } from '@/stores/userInfo'
import router from '@/router'

//API URLを取得
const apiBaseUrl = () => {
  const config = useStoreConfig()
  return config.apiOriginName + '/'
}

//HTTPヘッダを作成
const httpHeaders = () => {
  const config = useStoreConfig()
  const userInfo = useStoreUserInfo()
  return {
    'X_DTW_Signature': config.apikey,
    'X_DTW_Token': userInfo.userToken
  }
}

//htmlファイルのコンテンツを取得
export const getHtmlFile = async (fileName) => {
  const url = getHtmlFileName(fileName)
  const response = await fetch(url)
  return response.text()
}

// エラーハンドリング処理
axios.interceptors.response.use(function (response) {
  // API成功時はResponseを返す
  return response;

}, function (error) {
  // 失敗時の処理
  if (error.response) {

    //認証エラー、ログイン画面へ
    switch (error.response.data.error.code) {
      case 'A4010003':
      case 'A4010004':
      case 'A4010005':
        router.push({ name: 'login' })
        break;
      default:
      //
    }

    switch (error.response?.status) {
      case 400:
      case 401:
        throw error
      default: {
        // 400、401以外 のエラー（例外）処理  
        const messages = useStoreMessages()
        messages.add({ 'error': 'エラーが発生しました。再度お試しください。' })    
      }
    }  
  }
})



//APIを呼び出す
export const request = {
  //get処理(相対URL)
  async get(url, headers = httpHeaders()) {
    const response = await axios.get(apiBaseUrl() + url, { headers: headers })
    return response.data
  },
  //get処理(完全なURL)
  async getFullUrl(url, headers = httpHeaders()) {
    const response = await axios.get(url, { headers: headers })
    return response.data
  },
  //post処理
  async post(url, data, headers = httpHeaders()) {
    const response = await axios.post(apiBaseUrl() + url, data, { headers: headers })
    return response.data
  },
  //delete処理
  async delete(url, data, headers = httpHeaders()) {
    const response = await axios.delete(apiBaseUrl() + url, { headers: headers, data: data })
    return response.data
  }
}
